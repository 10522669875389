import axios from 'axios';
import { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { colors } from '../../assets/colors';
import invoierLogo from '../../assets/images/invoier.png';
import { customerKeyToDisplayName, getFormFields, getInitialValues } from '../../functions';
import { Line, RowView, Text } from '../../helpers';
import { IInvoice } from '../../interfaces';
import UserInfoSchema from '../../schemas/UserInfoSchema';
import { setGlobalState, useGlobalState } from '../../state';
import AnimatedLoader from '../AnimatedLoader/AnimatedLoader';
import Button from '../Button/Button';
import CustomForm from '../CustomForm/CustomForm';
import { BankIDLogo } from '../Logos';
import RoundBox from '../RoundBox/RoundBox';
import Address from './Address';
import PaymentProvider from './PaymentProvider';

const Wrapper = styled.div``;

type InvoiceUserInfoPropType = {
    customer: Partial<IInvoice['customer']>;
};

type InfoScreenType = {
    customer: IInvoice['customer'];
    screen: string;
};

type EditUserInfoProps = {
    editting?: boolean;
    customer: IInvoice['customer'];
};

const InvoiceDetails = () => {
    const [invoice] = useGlobalState('invoice');
    const [loading] = useGlobalState('loading');
    const [showInfo, setShowInfo] = useState<string>('start');

    const EditUserInfo = ({ customer }: EditUserInfoProps) => {
        const initialValues: Partial<IInvoice['customer']> = {
            email: invoice.customer.email,
            name: invoice.customer.name,
            street_address: invoice.customer.street_address,
            care_of: invoice.customer.care_of,
            postal_code: invoice.customer.postal_code,
            city: invoice.customer.city,
            phone_number: invoice.customer.phone_number
        };

        const FormFieldsConverter = (arr: string[]): { name: string }[] => {
            const result: { name: string }[] = [];
            arr.forEach((item) => {
                if (customerKeyToDisplayName(item) === '') {
                    return;
                }
                const obj: { name: string } = {
                    name: ''
                };
                obj.name = item;
                result.push(obj);
            });
            return result;
        };

        const submitUserInfoForm = async (values: Partial<IInvoice['customer']>) => {
            setGlobalState('loading', true);
            await axios
                .post(
                    process.env.REACT_APP_API_BASE_URL +
                        'web/invoice/' +
                        invoice.uuid +
                        '/sign_invoice/',
                    {
                        payment_method: invoice.payment_method,
                        id_number: invoice.customer.id_number,
                        customer_email: values.email,
                        phone_number: values.phone_number,
                        name: values.name,
                        street_address: values.street_address,
                        care_of: values.care_of,
                        postal_code: values.postal_code,
                        city: values.city,
                        intent_uuid: invoice.intent_uuid
                    }
                )
                .then((res) => (window.location.href = res.data.url))
                .catch((err) => {
                    console.log(err);
                });
            setGlobalState('loading', false);
        };

        const EditUserInfoFields = FormFieldsConverter(Object.keys(customer));

        return (
            <RoundBox>
                {loading && <AnimatedLoader />}
                <Text
                    i="Fakturauppgifter"
                    fontWeight="normal"
                    style={{ fontSize: '18px', marginBottom: '13px' }}
                />
                <Text
                    i="Organisationsnummer"
                    color={colors.lightGrey}
                    style={{ marginTop: '13px', marginBottom: '13px' }}
                />
                <Text
                    i={invoice.organization.id_number}
                    color="#2A2E43"
                    style={{ marginBottom: '29px' }}
                />

                <CustomForm
                    initialValues={initialValues}
                    validationSchema={UserInfoSchema}
                    onFormSubmit={submitUserInfoForm}
                    fields={EditUserInfoFields}
                    buttonText="Slutför"
                />
                {/* <Button
                    i="Slutför utan BankID"
                    color={colors.white}
                    fontWeight="bold"
                    style={{
                        marginTop: '8%',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '15px'
                    }}
                    onClick={() => {
                        setGlobalState('loading', true);
                        setGlobalState('loading', false);
                    }}/> */}
                {invoice.invoice_type === 'fast' && (
                    <RowView style={{ justifyContent: 'center', marginTop: '27px' }}>
                        <PaymentProvider
                            logoSrc={invoierLogo}
                            style={{ width: '100px', marginLeft: '5px' }}
                        />
                    </RowView>
                )}
            </RoundBox>
        );
    };

    const InvoiceUserInfo = ({ customer }: InvoiceUserInfoPropType) => {
        useEffect(() => {
            for (const key in customer) {
                if (
                    key === 'phone_number' ||
                    key === 'care_of' ||
                    key === 'id_number' ||
                    key === 'country'
                ) {
                    continue;
                } else if (customer[key as keyof IInvoice['customer']] === '') {
                    setShowInfo('manual');
                    break;
                }
            }
        }, []);

        const InvoiceInvoiceUserInfoSchema = Yup.object({
            email: Yup.string().email('Invalid email').required('Required'),
            phone_number: Yup.number()
        });

        const initialValues = getInitialValues(invoice.customer);

        const onSubmit = async (values: Partial<IInvoice['customer']>) => {
            setGlobalState('loading', true);
            await axios
                .post(
                    process.env.REACT_APP_API_BASE_URL +
                        'web/invoice/' +
                        invoice.uuid +
                        '/sign_invoice/',
                    {
                        payment_method: invoice.payment_method,
                        id_number: invoice.customer.id_number,
                        customer_email: values.email,
                        phone_number: values.phone_number,
                        name: invoice.customer.name,
                        street_address: invoice.customer.street_address,
                        care_of: invoice.customer.care_of,
                        postal_code: invoice.customer.postal_code,
                        city: invoice.customer.city,
                        intent_uuid: invoice.intent_uuid
                    }
                )
                .then((res) => (window.location.href = res.data.url))
                .catch((err) => {
                    console.log(err);
                });
            setGlobalState('loading', false);
        };
        return (
            <Wrapper>
                {loading && <AnimatedLoader />}
                <RoundBox style={{ padding: '16px' }}>
                    <Text
                        i="Fakturauppgifter"
                        fontWeight="normal"
                        style={{ fontSize: '18px', marginBottom: '13px' }}
                    />
                    <Address setShowInfo={setShowInfo} />
                    <CustomForm
                        initialValues={initialValues}
                        validationSchema={InvoiceInvoiceUserInfoSchema}
                        onFormSubmit={onSubmit}
                        fields={getFormFields(invoice.customer)}
                        buttonText="Slutför"
                    />
                    <Text
                        i="Använd BankID på annan enhet"
                        style={{ marginTop: '19px', textAlign: 'center' }}
                        color={colors.lightGrey}
                    />

                    {invoice.invoice_type === 'fast' && (
                        <>
                            <Line />
                            <RowView style={{ justifyContent: 'center' }}>
                                <PaymentProvider
                                    logoSrc={invoierLogo}
                                    style={{ width: '100px', marginLeft: '5px' }}
                                />
                            </RowView>
                        </>
                    )}
                </RoundBox>
            </Wrapper>
        );
    };

    const ConfirmedUserInfo = () => {
        return (
            <>
                {loading && <AnimatedLoader />}
                <Text
                    i="Fakturauppgifter"
                    fontWeight="normal"
                    style={{ fontSize: '18px', marginBottom: '13px' }}
                />
                <Address setShowInfo={setShowInfo} />
                <Button
                    i="Godkänn med BankID "
                    color={colors.white}
                    fontWeight="bold"
                    style={{
                        marginTop: '8%',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '15px'
                    }}
                    onClick={async () => {
                        setGlobalState('loading', true);
                        await axios
                            .post(
                                process.env.REACT_APP_API_BASE_URL +
                                    'web/invoice/' +
                                    invoice.uuid +
                                    '/sign_invoice/',
                                {
                                    payment_method: invoice.payment_method,
                                    id_number: invoice.customer.id_number,
                                    customer_email: invoice.customer.email,
                                    phone_number: invoice.customer.phone_number,
                                    name: invoice.customer.name,
                                    street_address: invoice.customer.street_address,
                                    care_of: invoice.customer.care_of,
                                    postal_code: invoice.customer.postal_code,
                                    city: invoice.customer.city,
                                    intent_uuid: invoice.intent_uuid
                                }
                            )
                            .then((res) => (window.location.href = res.data.url))
                            .catch((err) => {
                                console.log(err);
                            });
                        setGlobalState('loading', false);
                    }}>
                    <BankIDLogo />
                </Button>
                <Button
                    i="Godkänn utan BankID"
                    color={colors.white}
                    fontWeight="bold"
                    style={{
                        marginTop: '8%',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '15px'
                    }}
                    onClick={async () => {
                        setGlobalState('loading', true);
                        await axios
                            .post(
                                process.env.REACT_APP_API_BASE_URL +
                                    'web/invoice/' +
                                    invoice.uuid +
                                    '/sign_invoice/',
                                {
                                    payment_method: invoice.payment_method,
                                    id_number: invoice.customer.id_number,
                                    customer_email: invoice.customer.email,
                                    name: invoice.customer.name,
                                    street_address: invoice.customer.street_address,
                                    care_of: invoice.customer.care_of,
                                    postal_code: invoice.customer.postal_code,
                                    phone_number: invoice.customer.phone_number,
                                    city: invoice.customer.city,
                                    intent_uuid: invoice.intent_uuid
                                }
                            )
                            .then((res) => {
                                console.log(res);
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                        setGlobalState('loading', false);
                    }}
                />
            </>
        );
    };

    const InfoScreen = ({ customer, screen }: InfoScreenType): ReactElement => {
        switch (screen) {
            case 'start':
                return <InvoiceUserInfo customer={customer} />;
            case 'confirmed':
                return <ConfirmedUserInfo />;
            case 'manual':
                return <EditUserInfo customer={customer} />;

            default:
                return <div>Something went wrong</div>;
        }
    };

    return <InfoScreen customer={invoice.customer} screen={showInfo} />;
};

export default InvoiceDetails;
