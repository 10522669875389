import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const CheckoutFailed = () => {
    const { invoice_id } = useParams();

    const navigate = useNavigate();

    useEffect(() => {
        navigate(`/invoice/${invoice_id}/`, {
            replace: true,
            state: {
                failed: 'redirected'
            }
        });
    });

    return <></>;
};

export default CheckoutFailed;

//   {
//     invoice ? (
//       <Clamp>
//         <CustomerDetails />
//         {/* <PaymentMethods /> */}
//         {/* <BankIDButton /> */}
//         <InvoiceStatus status="failed" />
//         <UserInfo />
//       </Clamp>
//     ) : (
//       <AnimatedLoader />
//     );
//   }
