import { createGlobalState } from 'react-hooks-global-state';
import { IInvoice } from '../interfaces';

const invoice: IInvoice = {
    status: '',
    organization: {
        id: -1,
        id_number: -1,
        short_id_number: -1,
        name: '',
        uuid: ''
    },
    lines: [
        {
            type: '',
            description: '',
            vat_rate: -1,
            duration: '',
            distance: '',
            price_excl_vat: -1,
            price_incl_vat: -1,
            amount_excl_vat: -1,
            amount_incl_vat: -1,
            vat_amount: ''
        }
    ],
    vat: -1,
    invoice_number: '',
    due_date: null,
    uuid: '',
    invoice_type: '',
    is_signed: null,
    is_signing_required: null,
    total_amount: -1,
    fee: -1,
    due_days: -1,
    vat_rate: -1,
    reversed_vat: false,
    deduction: [],
    payment_provider: '',
    partner_invoice_number: false,
    payment_method: '',
    created: null,
    customer: {
        type: '',
        id_number: '',
        email: '',
        phone_number: '',
        name: '',
        street_address: '',
        care_of: '',
        postal_code: '',
        city: '',
        country: ''
    },
    payment_methods: [
        {
            id: '',
            description: '',
            legalInfoLinks: [
                {
                    appendPriceLast: false,
                    endUserDescription: '',
                    url: ''
                }
            ],
            specificType: ''
        }
    ],
    intent_uuid: '',
    attachments: []
};

const loading = false;

const { setGlobalState, useGlobalState } = createGlobalState({
    invoice: invoice,
    customer: invoice['customer'],
    loading: loading
});

export { useGlobalState, setGlobalState };
